var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.$screen.width <= 567
          ? _c(
              "div",
              [
                _c("MobileViewsLocationList", {
                  attrs: { locations: _vm.locations },
                  on: {
                    "main-location:show-image": _vm.showPictures,
                    "main-location:delete": _vm.handleMainLocationDelete,
                    "main-location:edit": _vm.handleEditLocation,
                    "main-location:add": _vm.initAndOpenNewLocation,
                    "main-location:change-option":
                      _vm.handleChangeShowOptionSelected,
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-row",
                  { staticClass: "form" },
                  [
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(2, "Locations list")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", xl: "12" } },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "slide" } },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "card-border-blue-light",
                                attrs: { "no-body": "" },
                              },
                              [
                                _c(
                                  "b-card-body",
                                  [
                                    _c(
                                      "b-row",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "my-1",
                                            attrs: {
                                              cols: `${
                                                _vm.$screen.width >= 992 ? 4 : 0
                                              }`,
                                            },
                                          },
                                          [
                                            _vm.$screen.width >= 992
                                              ? _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            9,
                                                            "Type to Search"
                                                          ),
                                                      },
                                                      model: {
                                                        value: _vm.filter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filter = $$v
                                                        },
                                                        expression: "filter",
                                                      },
                                                    }),
                                                    _c(
                                                      "b-input-group-append",
                                                      {
                                                        staticClass:
                                                          "cursor-pointer",
                                                      },
                                                      [
                                                        _c(
                                                          "b-input-group-text",
                                                          {
                                                            staticClass:
                                                              "btn-search",
                                                          },
                                                          [
                                                            _vm.filter
                                                              .length === 0
                                                              ? _c(
                                                                  _vm.getLucideIcon(
                                                                    "Search"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  _vm.getLucideIcon(
                                                                    "X"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.filter =
                                                                            ""
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              cols: `${
                                                _vm.$screen.width >= 992
                                                  ? 8
                                                  : 12
                                              }`,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fs-14 fw-700 mr-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                147,
                                                                "Show"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "padding-top": "15px",
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-group", {
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                ariaDescribedby,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "b-form-radio-group",
                                                                    {
                                                                      attrs: {
                                                                        id: "showOptionLocation",
                                                                        options:
                                                                          _vm.showOptions,
                                                                        "aria-describedby":
                                                                          ariaDescribedby,
                                                                        name: "radio-options",
                                                                        plain:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleChangeShowOptionSelected,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.showOptionSelected,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.showOptionSelected =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "showOptionSelected",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "4" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "float-right pl-3 pr-3",
                                                        attrs: {
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.initAndOpenNewLocation,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Plus"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getAddLabel
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      { staticClass: "mt-1" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", xl: "12" } },
                                          [
                                            _vm.$screen.width >= 992
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "location-list-container",
                                                  },
                                                  [
                                                    _c("b-table", {
                                                      staticStyle: {
                                                        "text-align": "left",
                                                      },
                                                      attrs: {
                                                        "selected-variant":
                                                          _vm.selectedColor,
                                                        hover: _vm.hover,
                                                        selectable: "",
                                                        "select-mode":
                                                          _vm.selectMode,
                                                        responsive: "sm",
                                                        items: _vm.locations,
                                                        fields: _vm.fields,
                                                        filter: _vm.filter,
                                                        "sticky-header":
                                                          "800px",
                                                        bordered: "",
                                                        striped: "",
                                                        small: "",
                                                        "head-variant": _vm.hv,
                                                        "empty-text":
                                                          _vm.FormMSG(
                                                            98,
                                                            "No locations found"
                                                          ),
                                                        "show-empty": "",
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.handleEditLocation,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "head(actionSelected)",
                                                            fn: function () {
                                                              return [
                                                                _vm.locations
                                                                  .length > 0
                                                                  ? _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          size: "sm",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.handleInputSelectAll,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectAll,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectAll =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectAll",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                          {
                                                            key: "cell(actionSelected)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleInputItemChecked(
                                                                            $event,
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .checked,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "checked",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.checked",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(view)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                data.item.images
                                                                  .length
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Image"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                                color:
                                                                                  "#B8BEC5",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn-transparent",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showPictures(
                                                                                    data
                                                                                      .item
                                                                                      .images
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "File"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    size: 22,
                                                                                    color:
                                                                                      "#47C7BF",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        728214348
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "pl-5 pr-5",
                                                attrs: {
                                                  size: "sm",
                                                  variant:
                                                    "custom-outline-gray",
                                                  disabled:
                                                    _vm.locationsIdToDelete
                                                      .length === 0,
                                                },
                                                on: {
                                                  click:
                                                    _vm.handleDeleteLocations,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(154, "Delete")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }